import { Container, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface ResponseUrlDisplayProps {
    description: string;
    url: string;
    name: string;
}

const ResponseUrlDisplay: React.FC<ResponseUrlDisplayProps> = ({ description, url, name }) => {
    return (
        <Container style={{paddingTop:'1rem',paddingBottom:'1rem'}}>
            <Grid container justifyContent="space-between">
             <Grid item >
                <Typography variant="h6">{description}</Typography>
             </Grid>
             <Grid item >
             <Link to={url}>{name}</Link>
             </Grid>
            </Grid>
            <Divider />
            </Container>
    );
};

export default ResponseUrlDisplay;
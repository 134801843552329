// Purpose: Contains utility functions for the StatsVisualizations component.

export class ChartUtils {

    // Converts the data format to a format that can be used by the component
    static convertDataFormat(data: any): any[] {
        let result: any[] = [];
        // check that the data is an object
        if (typeof data == 'object') {
            console.log("Data is  an object --- ", data);
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    result.push({
                        label: key,
                        value: data[key]
                    });
                }
            }
            console.log("ChartUtils:convertDataFormat --> Conversion Complete --- Data --- ", result);
            return result;
        } else if (Array.isArray(data)) {
            result = data;
        } else {
            throw new Error("Data is not compatible with the component");
        }
        return result;
    }
}
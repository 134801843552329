import * as React from 'react';
import {useEffect} from 'react';
import {styled, ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import DashboardPage from '../Dashboard/Dashboard';
import Logo from '../../components/GaussianLogo';
import Jobs from '../Jobs/Jobs';
import JobDetails from '../JobDetails/JobDetails';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "./../../components/JobDetailsMap/JobDetailsMap.scss"
import "./../../components/JobDetailsMap/JobDetailMapBox.scss"
import CreateNewJob from '../Jobs/CreateNewJob';
import {AppTheme} from '../../theme/AppTheme';
import CircularProgressIndicator from "../../components/CustomComponents/CircularProgressIndicator";
import DynamicFlow from "../DynamicFlow/DynamicFlow";
import Typography from "@mui/material/Typography";
import {StatsVisualizer} from "../../components/StatsVisualizations/StatsVisualizer";
import UploadPage from "../UploadFile/UploadPage";
import TmStatsPage from '../TmStats/TmStatsPage';


const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`

const drawerWidth = 240;

declare module '@mui/material/styles' {
    interface Palette {
        ochre: Palette['primary'];
    }

    interface PaletteOptions {
        ochre?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        ochre: true;
    }
}

interface FlowType {
    name: string;
    id: string;
    steps: [{ task: string }]
}

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    })
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function Root() {
    const [open, setOpen] = React.useState(false);
    const location = useLocation();
    const {id} = useParams<{ id?: string }>();
    const navigate = useNavigate();
    const [flowTypes, setFlowTypes] = React.useState<[FlowType]>();
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState('')
    const [pageTitle, setPageTitle] = React.useState('')

    useEffect(() => {
        fetch(API_BASE_URL + '/flows')
            .then(response => response.json())
            .then(data => {
                setFlowTypes(data as [FlowType]);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(error);
            });
    }, []);
    console.log("---", error)
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const nav = () => {
        navigate("/fix-tags")
    }

    const handle_fix_crossings = () => {
        handleDrawerClose()
        setTimeout(nav, 250);
    }

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleDynamicFlows = (flow_id: string, name: string) => {
        handleDrawerClose()
        setPageTitle(name)
        navigate(`/flow/${flow_id}`)
    }

    let currentPage;
    console.log(location.pathname)
    console.log(id ? `/flow/${id}` : 'Nothing')
    switch (location.pathname) {
        case '/':
            currentPage = <CreateNewJob/>;
            break;
        case '/upload-file':
            currentPage = <UploadPage/>;
            break;
        case '/fix-crossings':
        case '/jobs':
            currentPage = <Jobs/>;
            break
        case `/job/${id}`:
            currentPage = <JobDetails/>;
            break;
        case '/fix-tags':
            currentPage = <CreateNewJob/>
            break;
        case `/flow/${id}`:
            currentPage = <DynamicFlow/>
            break;
        case '/stats':
            currentPage = <StatsVisualizer  data={{title:"",data:[]}}/>
            break;
        case '/tmstats':
            currentPage = <TmStatsPage/>;
            break;
            default:
            currentPage = <DashboardPage/>;

    }

    if (loading) {
        return (<CircularProgressIndicator/>);
    }

    return (
        <ThemeProvider theme={AppTheme}>
            <Box sx={{display: 'flex'}}>
                <AppBar position="fixed" open={open} sx={{bgcolor: "ochre.dark"}}>
                    <Toolbar sx={{bgcolor: "ochre.dark"}}>
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{mr: 2, ...(open && {display: 'none'})}}
                        >
                            <MenuIcon color='primary'/>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2}}
                            onClick={() => navigate("/")}
                        >
                            <Logo/>
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" color="white">
                            {pageTitle}
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            backgroundColor: '#1F212B'
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader sx={{bgcolor: "ochre.dark"}}>
                        <IconButton onClick={handleDrawerClose} color='primary'>
                            {AppTheme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>


                    <List>
                        <>
                        <ListItemButton onClick={handleDrawerClose} component={Link} to="/tmstats">
                                <ListItemText primary='Get TM Project Stats'/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton onClick={handleDrawerClose} component={Link} to="/upload-file">
                                <ListItemText primary='Upload File'/>
                            </ListItemButton>
                            <Divider/>
                            <ListItemButton onClick={handleDrawerClose} component={Link} to="/jobs">
                                <ListItemText primary='Search Jobs'/>
                            </ListItemButton>
                            <Divider/>
                            {flowTypes !== undefined && flowTypes.map((flow) => (
                                <div>
                                    <ListItemButton onClick={() => handleDynamicFlows(flow.id, flow.name)}>
                                        <ListItemText primary={flow.name}/>
                                    </ListItemButton>
                                    <Divider/>
                                </div>
                            ))}
                        </>
                    </List>
                </Drawer>
                <Main open={open}>
                    <DrawerHeader/>
                    <Box>{currentPage}</Box>
                </Main>
            </Box>
        </ThemeProvider>
    );
}
// LineChartComponent renders the line chart for the stats data

import React, {useEffect, useState} from "react";
import {LineChart} from "@mui/x-charts";
import {makeStyles} from "@material-ui/core";
import {ChartUtils} from "./ChartUtils";
import './ChartStyles.css';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'black'// Change this to the color you want
    },
});

interface LineChartComponentProps {
    chartData: any;
}

export const LineChartComponent: React.FC<LineChartComponentProps> = ({chartData}) => {
    const [lineChartData, setLineChartData] = useState<any[]>([]);
    const classes = useStyles();
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (chartData && Array.isArray(chartData)) {
            if (chartData.length > 0 && chartData[0].label) {
                setLineChartData(chartData);
            }
        } else if (chartData && typeof chartData === 'object') {
            const convertedData: any[] = ChartUtils.convertDataFormat(chartData);
            console.log("LineChartComponent:: Converted Data --- ", convertedData);
            if (convertedData && convertedData.length > 0 && convertedData[0].label) {
                setLineChartData(convertedData);
            }
        }
    }, []);

    const yLabelFormatter = (value: number | null) => {
        if (value != null && typeof value === 'number' && value > 1000) {
            value = Math.round(value) / 1000
            return value + "k";
        } else if (value === null) {
            return "";
        } else if (value != null && typeof value === 'number' && value < 1000) {
            return value.toString();
        } else if (value != null && typeof value === 'string') {
            return value;
        }
        return "";
    };
    return (
        <div className='container-margin'>
            <LineChart
                dataset={lineChartData}
                width={800}
                height={500}
                tooltip={{
                    classes: classes,
                }}
                xAxis={[{scaleType: 'band', dataKey: 'label'}]}
                series={[
                    {dataKey: 'value'},
                ]}
                yAxis={[{
                    dataKey: 'label',
                    valueFormatter: (value: string) => yLabelFormatter(value as unknown as number)
                }]}
            />
        </div>
    );
}
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CircularProgressIndicator from '../CustomComponents/CircularProgressIndicator';
import {IconButton, TextField, Tooltip,} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import JobDetail from "./JobDetail";

interface JobResponse {
    input_url: string,
    footway_url: string,
    problem_ways_url: string,
    ways_fixed_url: string,
    crossings_fixed_url: string,
    messageType: string
}

interface Job {
    jobId: string;
    flowId: string;
    currentTask: string;
    request: Object;
    response: JobResponse;
    state: string;
}

const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`

const JobsListTable = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [job, setJob] = useState<Job>();
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    /// Method to fetch jobs data from api
    const fetchData = () => {
        setLoading(true);
        fetch(API_BASE_URL + 'job/' + searchTerm)
            .then(response => response.json())
            .then(data => {
                setJob(data as Job);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(error);
            });
    }

    const pop = () => {
        navigate(-1);
    };

    if (error) {
        return (
            <div>
                <p>Error: {error.toString()}</p>
                <button onClick={pop}>Go Back</button>
            </div>
        );
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: "16px"
            }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{width: "350px", marginRight: "5px"}}
                />
                <div>
                    <Tooltip title='Search for Job' arrow>
          <span>
            <IconButton color='primary' aria-label='view ' sx={{
                "&.Mui-disabled": {
                    color: "#c0c0c0"
                }
            }} onClick={fetchData}>
              <SearchIcon/>
            </IconButton>
          </span>
                    </Tooltip>
                </div>
            </div>
            {loading ? <CircularProgressIndicator/> : <div style={{minWidth: '60%'}}>
                <JobDetail job={job}/>
            </div>
            } </div>
    );
};

export default JobsListTable;


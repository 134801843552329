import React from "react";
import Button from "@mui/material/Button";
import DragDropView from "../DragDropView/DragDropView";
import {makeStyles} from "@material-ui/core";
import {CreateJobMapProps, JsonViewerProps} from "./MapViewWithInput";
import { Stack } from "@mui/material";
// import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        margin: theme.spacing(1), // Adjust the gap between buttons as needed
    },
}));

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   }));

const JsonViewer: React.FC<JsonViewerProps> = ({onGeoJsonUpdate, handleCopyToClipboard}) => {
    const classes = useStyles();

    const handleMapDataUpdate = (updatedGeoJson: any) => {
        console.log("In json Viewer")
        console.log(updatedGeoJson);
        onGeoJsonUpdate(updatedGeoJson);
    };

    return (
        <>
            {/* <Stack direction="column" spacing={2}> */}
                <div>
                    <DragDropView
                        onGeoJsonUpdate={handleMapDataUpdate}
                    />
                </div>
                    <div >
                    <pre id='json'/>
                    </div>
            {/* </Stack> */}
        </>
    );
}

export default JsonViewer;
// This class visualizes the data as a pie chart. It is called by the StatsVisualizer based on the data
// passed to it. The data is passed as a prop to the PieChart component which then visualizes the data.


import React, {useEffect, useState} from "react";
import {PieChart} from "@mui/x-charts";
import {makeStyles} from "@material-ui/core";
import {ChartUtils} from "./ChartUtils";
import {Container} from "@mui/material";


const useStyles = makeStyles({
    root: {
        backgroundColor: 'black'// Change this to the color you want
    },
});

interface PieChartComponentProps {
    chartData: any; // Replace 'any' with the type of your data
    labelPostfix?: string;
    labelPrefix?: string;
}

export const PieChartComponent: React.FC<PieChartComponentProps> = ({
                                                                        chartData,
                                                                        labelPostfix,
                                                                        labelPrefix

                                                                    }) => {
    const [pieChartData, setPieChartData] = useState<any[]>([]);
    const classes = useStyles();
    const [label, setLabel] = useState('');

    useEffect(() => {
        console.log("useEffect of PieChartComponent --- ", chartData);
        if (chartData && Array.isArray(chartData)) {
            if (chartData.length > 0 && chartData[0].label) {
                setPieChartData(chartData);
            }
        } else if (chartData && typeof chartData === 'object') {
            const convertedData = ChartUtils.convertDataFormat(chartData);
            setPieChartData(convertedData);
        }
    }, []);

    const arcLabelFormatter = (value: number | null) => {
        if (value != null && typeof value === 'number' && value > 1000) {
            value = Math.round(value) / 1000
            return value + "k";
        } else if (value === null) {
            return "";
        } else if (value != null && typeof value === 'number' && value < 1000) {
            return value.toString();
        } else if (value != null && typeof value === 'string') {
            return value;
        }
        return "";
    };

    function getLabelToDisplay(item: any) {
        console.log("Item --- ", item);
        if (item.label) {
            if (labelPrefix)
                return labelPrefix + ":" + item.value;
            else if (labelPostfix)
                return item.value + " " + labelPostfix;
            else
                return arcLabelFormatter(item.value);
        } else {
            return ""
        }
    }

    return (
        <Container>

            <PieChart
                dataset={pieChartData}
                width={800}
                height={500}
                tooltip={{
                    classes: classes,
                }}
                series={[
                    {
                        arcLabel: (item) => getLabelToDisplay(item),
                        data: pieChartData
                    },
                ]}

            />
        </Container>
    );
}
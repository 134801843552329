import React, {useEffect, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';


mapboxgl.accessToken = `${process.env.REACT_APP_MAP_KEY}`

export interface CreateJobMapProps {
    onGeoJsonUpdate: (updatedGeoJson: Object) => void;
}

const CreateJobMap: React.FC<CreateJobMapProps> = ({onGeoJsonUpdate}) => {
    const [zoom, setZoom] = useState(12);
    const [generatedPolygon, setGeneratedPolygon] = useState();

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: 'mapBox',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [-118.25448741309731, 34.041946105119706],
            zoom: zoom
        });

        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
                line_string: false,
                point: false
            },
            styles: [
                {
                    "id": "gl-draw-polygon-fill",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
                    "paint": {
                        "fill-color": "#e2a2a2",
                        "fill-outline-color": "#e2a2a2",
                        "fill-opacity": 0.8
                    }
                },
                {
                    "id": "gl-draw-polygon-stroke-active",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#03036e",
                        // "line-dasharray": [0.2, 2],
                        "line-width": 4
                    }
                },
                {
                    "id": "gl-draw-polygon-and-line-vertex-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"],],
                    "paint": {
                        "circle-radius": 3,
                        "circle-color": "#03036e",
                    }
                },
                {
                    "id": "gl-draw-line",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#03036e",
                        "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                }
            ]
        });
        map.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl
            }),
            'top-left'
        );
        map.addControl(draw);
        map.addControl(new mapboxgl.NavigationControl());
        map.on('zoomend', () => {
            console.log('A zoomend event occurred.');
            setZoom(map.getZoom());
        });

        map.on('draw.create', updateArea);
        map.on('draw.delete', updateArea);
        map.on('draw.update', updateArea);
        map.on('draw.add', updateArea);

        function updateArea() {
            const data = draw.getAll();
            const geojson = JSON.stringify(data);
            const jsonObject = JSON.parse(geojson);
            setGeneratedPolygon(jsonObject)
            if (jsonObject !== null) {
                onGeoJsonUpdate(jsonObject);
            } else {
                console.error("Element with ID 'json' not found");
            }
        }

        return () => map.remove();
    }, []);
    return (
        <div className='mapboxCon'>
            <div className='mapboxCon-container'>
                <div id='mapBox'/>
            </div>
        </div>
    );
}
export default CreateJobMap;
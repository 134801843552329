import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Divider from '@mui/material/Divider';


interface ResponseStringDisplayProps {
    paramDescription: string;
    value: string;
}

const ResponseStringDisplay: React.FC<ResponseStringDisplayProps> = ({ paramDescription, value }) => {
    return (
        <Container style={{paddingTop:'1rem',paddingBottom:'1rem'}}>
            <Grid container justifyContent="space-between">
             <Grid item >
                <Typography variant="h6">{paramDescription}</Typography>
                {/* {paramDescription} */}
             </Grid>
             <Grid item >
             <Typography variant="h6">{value}</Typography>
             </Grid>
            </Grid>
            <Divider />
            </Container>
    );
};

export default ResponseStringDisplay;
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import DynamicFlowForm from "../../components/DynamicFlowForm/DynamicFlowForm";
import './DynamicFlow.css'
import { Container } from '@mui/material';

const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`

export interface FlowTask {
    name: string,
    description: string,
    inputs: [{ name: string, description: string, type: string,options:[{name:string,value:string,description:string}] }],
    first_task: {
        id: string,
        inputs: [{ name: string, description: string, type: string,options:{} }],
    }
}

const DynamicFlow = () => {

    const {id} = useParams<{ id?: string }>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [flowTask, setFlowTask] = useState<FlowTask>();
    const [postData, setPostData] = useState(null);
    const navigate = useNavigate();

    const makeAPICall = (params: any) => {
        console.log("MAKE API Called")
        console.log(params)
        setLoading(true)
        postDataToServer(params)
    }

    const postDataToServer = async (param: any) => {
        const params = {
            "flowId": id,
            "data": param
        }
        console.log(params)
        try {
            const response = await fetch(API_BASE_URL + '/flow', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            });
            setLoading(false)
            if (!response.ok) {
                throw new Error('Failed to post data');
            }
            const responseData = await response.json();
            setPostData(responseData);
            navigate("/job/" + responseData.jobId)
            console.log(responseData);
        } catch (error) {
            setLoading(false)
            console.error('Error posting data:', error);
        }
    };


    useEffect(() => {
        setLoading(true)
        fetch(API_BASE_URL + '/flow/' + id)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setFlowTask(data as FlowTask);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(error);
            });
    }, [id]);

    return (
        <>
        <Container>
            {flowTask ? <div>
                <div className='h1Text' style={{color: 'white', textAlign: 'center'}}
                     dangerouslySetInnerHTML={{__html: flowTask.description}}/>

                <DynamicFlowForm inputs={flowTask.inputs} makeAPICall={makeAPICall} loading={loading}/>
            </div> : null}
            </Container>
        </>
    )
}

export default DynamicFlow;
import React, { useState, DragEvent } from "react";
import { Box, Typography } from '@mui/material';

const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`

const UploadPage = () => {
    const [uploading, setUploading] = useState(false);
    const [uploadStatusMessage, setUploadStatusMessage] = useState("");

    const handleFileUpload = async (file: File | null) => {
        if (!file) return; // Ensure file is not null

        setUploading(true);

        try {
            const formData = new FormData();
            formData.append("file", file);

            const response = await fetch(API_BASE_URL + '/util/upload', {
                method: "POST",
                body: formData
            });

            if (response.ok) {
                console.log("File uploaded successfully!");
                setUploading(false);
                setUploadStatusMessage("File uploaded successfully!");
            } else {
                console.error("Failed to upload file.");
                setUploading(false);
                setUploadStatusMessage("Failed to upload file.");
            }
        } catch (error) {
            console.error("An error occurred while uploading the file:", error);
            setUploading(false);
            setUploadStatusMessage("An error occurred while uploading the file.");
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        handleFileUpload(file);
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: '100vh',
            paddingTop: '50px'
        }}>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{
                margin: "10px",
                padding: "20px",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#2C2E3D"
            }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Upload File
                </Typography>
                <div
                    className="bg-gray-500 border border-gray-300 rounded-lg shadow-lg p-6"
                    style={{ width: '100%', maxWidth: '400px' }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <label htmlFor="dropzone-file"
                           className="flex flex-col p-5 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-500 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg className="w-4 h-4 m-40 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">Click to upload</span> or drag and drop
                            </p>
                        </div>
                        <input id="dropzone-file" style={{ color: 'transparent' }} type="file"
                               onChange={(e) => handleFileUpload(e.target.files ? e.target.files[0] : null)} className="hidden text-white"
                               accept=".geojson"/>
                    </label>

                    {uploading && <p>Uploading file...</p>}
                    {uploadStatusMessage && <p>{uploadStatusMessage}</p>}
                </div>
            </Box>
        </div>
    );
};

export default UploadPage;

import {Route, Routes} from "react-router-dom";
import Root from "./Root/Root";
import CreateNewJob from "./Jobs/CreateNewJob";
import DashboardPage from "./Dashboard/Dashboard";
import Jobs from "./Jobs/Jobs";
import JobDetails from "./JobDetails/JobDetails";
import DynamicFlow from "./DynamicFlow/DynamicFlow";
import {StatsVisualizer} from "../components/StatsVisualizations/StatsVisualizer";
import UploadPage from "./UploadFile/UploadPage";
import TmStatsPage from "./TmStats/TmStatsPage";


function AppRouter() {
    return (
        // <BrowserRouter>
        <Routes>
            <>
                <Route>
                    <Route path="/" element={<Root/>}>
                        <Route path="/dashboard" element={<DashboardPage/>}/>
                        <Route path='/upload-file' element={<UploadPage/>}/>
                        <Route path='/fix-tags' element={<CreateNewJob/>}/>
                        <Route path='/jobs' element={<Jobs/>}/>
                        <Route path='/job/:id' element={<JobDetails/>}/>
                        <Route path='/flow/:id' element={<DynamicFlow/>}/>
                        <Route path='/stats' element={<StatsVisualizer/>}/>
                        <Route path='/tmstats' element={<TmStatsPage/>}/>
                    </Route>
                </Route>
            </>
        </Routes>
        // </BrowserRouter>
    );
}

export default AppRouter;

// This class visualizes the data.json file in the form of multiple charts
// for distances = Bar Chart and for the counts = Pie Chart, for intersections = Histogram

import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {BarChartComponent} from "./BarChartComponent";
import {Container, Typography} from "@mui/material";

import BarSelectionComponent from "./BarSelectionComponent";
import {LineChartComponent} from "./LineChartComponent";
import {PieChartComponent} from "./PieChartComponent";

const useStyles = makeStyles({
    root: {
        backgroundColor: 'black'// Change this to the color you want
    },
});

interface GraphItem {
    label: string,
    value: number
}

interface GraphData {
    title: string,
    data: GraphItem[]
}

interface StatsVisualizerProps {

    data: GraphData;
}


export const StatsVisualizer: React.FC<StatsVisualizerProps> = ({data}) => {
    const [graphData, setGraphData] = useState<GraphItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [alignment, setAlignment] = React.useState<string | null>('left');
    const [chartType, setChartType] = React.useState<string>('bar');
    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        setAlignment(newAlignment);
    };


    useEffect(() => {
        setTitle(data.title);
        setGraphData(data.data)
        setChartType("bar");

    }, []);

    const handleChartSelection = (chartType: string | null) => {
        if (chartType === null) {
            chartType = 'bar';
        }
        setChartType(chartType.trim());
    }
    const classes = useStyles();

    if (isLoading) {
        return <div>Loading...</div>;
    } else {
        return (
            <Container>
                <BarSelectionComponent onChartTypeSelected={handleChartSelection}/>
                <Typography variant="h4" component="h2" align="center"> {title}</Typography>
                {(graphData.length > 0 && chartType?.trim() === "bar") &&
                    <BarChartComponent chartData={graphData}/>}
                {(graphData.length > 0 && chartType?.trim() === "line") &&
                    <LineChartComponent chartData={graphData}/>}
                {(graphData.length > 0 && chartType?.trim() === "pie") &&
                    <PieChartComponent chartData={graphData}/>}

            </Container>
        );
    }
}
import { Button, Card, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import { TextField } from '@mui/material';
// geojsonarea = require('@mapbox/geojson-area');
import { geometry } from '@mapbox/geojson-area';
import { set } from 'date-fns';
import CircularProgressIndicator from '../../components/CustomComponents/CircularProgressIndicator';

interface TMTask {
    geometry: any;
    properties: {
        taskStatus: string;
    }
}
interface ProjectStats {
    totalArea: number;
    mappedArea: number;
    progressPercent: number;
}


const TmStatsPage: React.FC = () => {
    function onCalculate() {
        console.log('Calculate button clicked');
        // Get the value from the input field
        console.log(input);
        // Make a request to the backend API
        // Check if input is a valid URL
        if (!isValidUrl(input)) {
            console.log('Invalid URL');
            setErrShort('Invalid URL');
            setOpError(true);
            return;
        } else {
            setErrShort('');
            setOpError(false);
            console.log('Valid URL given');
        }
        setProjectStats(undefined);
        fetchInformation();
    }

    const fetchInformation = async () => {
        setIsLoading(true);
        const inputUrl = new URL(input);
        const host = inputUrl.host;
        const scheme = inputUrl.protocol;
        const projectId = inputUrl.pathname.split('/').at(-1);
        const tasksUrl = `${scheme}//${host}/api/v2/projects/${projectId}/tasks/`;
        const tasks = (await (await fetch(tasksUrl)).json()).features as TMTask[];
        console.log(tasks);
        const area = geometry(tasks[0].geometry);
        console.log(area);
        const totalArea = tasks.reduce((total, task) => total + geometry(task.geometry), 0);
        const mappedArea = tasks
            .filter(f => f.properties.taskStatus === 'MAPPED')
            .reduce((total, task) => total + geometry(task.geometry), 0);
        const progressPercent = (mappedArea / totalArea) * 100;
        console.log('Total Area:', Math.round(totalArea));
        console.log('Mapped Area:', Math.round(mappedArea));
        console.log('Progress:', Math.round(progressPercent));
        setProjectStats({
            totalArea: Math.round(totalArea),
            mappedArea: Math.round(mappedArea),
            progressPercent: Math.round(progressPercent),
        });
        setIsLoading(false);
    }
    // Change later
    const isValidUrl = (url: string) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };
    const [input, setInput] = useState('');
    const [opError, setOpError] = useState(false);
    const [errShort, setErrShort] = useState('');
    const [projectStats, setProjectStats] = useState<ProjectStats>();
    const [isLoading, setIsLoading] = useState(false);


    return (
        <Container>
            <Typography variant="h4">Tasking Manager Project Stats</Typography>
            <Typography variant="body1">Calculate the area of mapped tasks for the specified tasking manager project.</Typography>
            {/* Add your content here */}

            <TextField helperText={errShort} error={opError} fullWidth margin="normal" value={input} onChange={(e) => { setInput(e.target.value) }} label="Enter Project Url" type='url' size='small' />
            <Button variant="contained" color="primary" onClick={onCalculate}>Calculate</Button>
            {isLoading && <CircularProgressIndicator />}
            {projectStats && 
            <Container>
                
                <Typography variant="h6">Project Stats</Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table sx={{ minWidth: 400, backgroundColor: 'black' }} >
                        <TableHead>
                            <TableRow>
                                <TableCell> Metric</TableCell>
                                <TableCell align="right">Value</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key='total-area'>
                                <TableCell  >Total Area</TableCell>
                                <TableCell align='right'>{projectStats?.totalArea} m<sup>2</sup></TableCell>
                            </TableRow>
                            <TableRow key='mapped-area'>
                                <TableCell  >Mapped Area</TableCell>
                                <TableCell align='right'>{projectStats?.mappedArea}  m<sup>2</sup></TableCell>
                            </TableRow>
                            <TableRow key='mapped-percentage'>
                                <TableCell  >Progress</TableCell>
                                <TableCell align='right'>{projectStats?.progressPercent}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
}

        </Container>
    );
};

export default TmStatsPage;
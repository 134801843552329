import MapViewWithInput from "../MapViewWithInput/MapViewWithInput";
import Button from "@mui/material/Button";
import React from "react";
import { Checkbox, ListItemText, TextField, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgressIndicator from "../CustomComponents/CircularProgressIndicator";
import InputLabel from '@mui/material/InputLabel';


const DynamicFlowForm = (props) => {
    const { inputs, makeAPICall, loading } = props
    const [formData, setFormData] = React.useState({});


    const updateParams = (key, value) => {

        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    const handleJsonUpdate = (data, input) => {
        updateParams(input, data)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateParams(name, value)
    };
    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        updateParams(name, value)
    }

    const handleMultiSelectChange = (e) => {
        const { name, value } = e.target;
        const selectedValues = value.filter((val) => val !== '').join(',')
        updateParams(name, selectedValues)
    }

    const renderView = (input) => {
        switch (input.type) {
            case 'geojson':
                return <MapViewWithInput
                    onGeoJsonUpdate={(updatedGeoJson) => handleJsonUpdate(updatedGeoJson, input.name)} />;

            case 'string':
            case 'url':
                return <div style={{ width: '100%' }}>

                    <TextField
                        placeholder={input.description}
                        variant="outlined"
                        name={input.name}
                        onChange={(e) => handleInputChange(e)}
                        style={{ width: '50%', marginTop: '2rem' }}
                    />
                </div>;
            case 'enum':
                return <div style={{ width: '100%' }}>

                    <Typography variant="h6" style={{ color: 'white' }}>{input.description}</Typography>
                    <Select
                        label={input.description}
                        name={input.name}
                        sx={{ width: '50%', marginTop: '2rem' }}
                        onChange={handleSelectChange}
                        value={formData[input.name]??''}
                        inputProps={{
                            
                            MenuProps: {
                                MenuListProps: {
                                    sx: {
                                        backgroundColor: '#1F212B'
                                    }
                                }
                            }
                        }}

                    >
                        {input.options.map((option) => (
                            <MenuItem value={option.value}>{option.name}</MenuItem>

                        ))}

                    </Select>

                </div>;
             case 'multiselect':
                return <div style={{ width: '100%' }}>

                    <Typography variant="h6" style={{ color: 'white' }}>{input.description}</Typography>
                    <Select
                        label={input.description}
                        name={input.name}
                        multiple
                        sx={{ width: '50%', marginTop: '2rem' }}
                        onChange={handleMultiSelectChange}
                        value={formData[input.name]?formData[input.name].split(','):[]}
                        renderValue={(selected) => selected.join(', ')}
                        inputProps={{
                            
                            MenuProps: {
                                MenuListProps: {
                                    sx: {
                                        backgroundColor: '#1F212B'
                                    }
                                }
                            }
                        }}

                    >
                        {input.options.map((option) => (
                            <MenuItem value={option.value}>
                            <Checkbox checked={formData[input.name]?.includes(option.value)} />
                            <ListItemText primary={option.name} />
                            </MenuItem>

                        ))}

                    </Select>

                </div>;
            default:
                return <div style={{ color: 'white' }}>View not implemented</div>;
        }
    };


    return (
        <>
            {inputs.map((input) => (
                renderView(input)
            ))}
            <div className='container-dff' style={{ marginTop: '1rem' }}>
                {loading && <CircularProgressIndicator className='item' />}
                <Button className='container-button' variant="contained"
                    onClick={() => {
                        makeAPICall(formData)
                    }}>Submit</Button>
            </div>
        </>
    );
}
export default DynamicFlowForm
import React, {useEffect, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "./MapViewWithInput.scss"
import {Box} from '@mui/material';
import {syntaxHighlight} from "./utils";
import JsonViewer from "./JsonViewer";


mapboxgl.accessToken = `${process.env.REACT_APP_MAP_KEY}`

export interface CreateJobMapProps {
    onGeoJsonUpdate: (updatedGeoJson: Object) => void,
}

export interface JsonViewerProps {
    onGeoJsonUpdate: (updatedGeoJson: Object) => void,
    handleCopyToClipboard: () => void
}

const CreateJobMap: React.FC<CreateJobMapProps> = ({onGeoJsonUpdate}) => {
    const [zoom, setZoom] = useState(12);
    const [generatedPolygon, setGeneratedPolygon] = useState();
    const [map, setMap] = useState<mapboxgl.Map>();
    const [draw, setDraw] = useState<MapboxDraw>();
    const [addedLayerId, setAddedLayerId] = useState<string | null>(null);
    const [selectedFeatureId, setSelectedFeatureId] = useState<string | null>(null);


    useEffect(() => {

        const map = new mapboxgl.Map({
            container: 'mapBox',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [-118.25448741309731, 34.041946105119706],
            zoom: zoom
        });

        setMap(map);
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
                line_string: false,
                point: false,
            },
            styles: [
                // ACTIVE (being drawn)
                // line stroke
                {
                    "id": "gl-draw-line",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#03036e",
                        "line-width": 4
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                    "paint": {
                        "fill-color": "#e2a2a2",
                        "fill-outline-color": "#e2a2a2",
                        "fill-opacity": 0.8
                    }
                },
                // polygon mid points
                {
                    'id': 'gl-draw-polygon-midpoint',
                    'type': 'circle',
                    'filter': ['all',
                        ['==', '$type', 'Point'],
                        ['==', 'meta', 'midpoint']],
                    'paint': {
                        'circle-radius': 3,
                        'circle-color': '#fbb03b'
                    }
                },
                // polygon outline stroke
                // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                {
                    "id": "gl-draw-polygon-stroke-active",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#03036e",
                        // "line-dasharray": [0.2, 2],
                        "line-width": 4
                    }
                },
                // vertex point halos
                {
                    "id": "gl-draw-polygon-and-line-vertex-halo-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 5,
                        "circle-color": "#FFF"
                    }
                },
                // vertex points
                {
                    "id": "gl-draw-polygon-and-line-vertex-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 3,
                        "circle-color": "#03036e",
                    }
                },
                // INACTIVE (static, already drawn)
                // line stroke
                {
                    "id": "gl-draw-line-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill-static",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "paint": {
                        "fill-color": "#000",
                        "fill-outline-color": "#000",
                        "fill-opacity": 0.1
                    }
                },
                // polygon outline
                {
                    "id": "gl-draw-polygon-stroke-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                }
            ]
        });
        setDraw(draw)
        map.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl
            }),
            'top-left'
        );
        map.addControl(draw);
        map.addControl(new mapboxgl.NavigationControl());
        map.on('zoomend', () => {
            console.log('A zoomend event occurred.');
            setZoom(map.getZoom());
        });

        // Function to handle delete event
        const handleDelete = () => {
            updateArea()
            if (!map || !draw || !addedLayerId || !selectedFeatureId || addedLayerId !== selectedFeatureId) return;

            map.removeLayer(addedLayerId);
            map.removeSource('uploaded-data');
            setAddedLayerId(null);
        };

        map.on('draw.create', updateArea);
        map.on('draw.delete', handleDelete);
        map.on('draw.update', updateArea);
        map.on('draw.add', updateArea);

        const handleSelectedFeatures = () => {
            const selectedFeatures = draw.getSelected();

            if (selectedFeatures.features.length > 0) {
                // At least one feature is selected
                setSelectedFeatureId(selectedFeatures.features[0].id + "");
            } else {
                // No features are selected
                setSelectedFeatureId(null);
            }
        };

        map.on('draw.selectionchange', handleSelectedFeatures);

        function updateArea() {
            const data = draw.getAll();
            const geojson = JSON.stringify(data);
            const jsonObject = JSON.parse(geojson);
            setGeneratedPolygon(jsonObject)
            if (document.getElementById('json') != undefined) {
                document.getElementById('json')!.innerHTML = syntaxHighlight(JSON.stringify(jsonObject, null, 2));
            }

            if (jsonObject !== null) {
                onGeoJsonUpdate(jsonObject);
            } else {
                console.error("Element with ID 'json' not found");
            }
        }

        return () => map.remove();
    }, []);

    const handleGeoJsonUpdate = (data: any) => {
        // Handle the updated GeoJSON data here
        console.log('Updated GeoJSON data:', data);
        setGeneratedPolygon(data); // Optionally, set the data to state for further use
        if (document.getElementById('json') != undefined) {
            document.getElementById('json')!.innerHTML = syntaxHighlight(JSON.stringify(data, null, 2));
        }
    };

    const addLayer = (data: any) => {
        console.log('Adding layer')
       draw?.add(data)
       // Get the bounds for the geojson
       const coordinates = data.features[0].geometry.coordinates[0]
       const bounds = new mapboxgl.LngLatBounds(
        coordinates[0],
        coordinates[0]
        );
        for (const coord of coordinates) {
            bounds.extend(coord);
        }
        
        map?.fitBounds(bounds, {
            padding: 10
        });

        handleGeoJsonUpdate(data)
        onGeoJsonUpdate(data);
        
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(JSON.stringify(generatedPolygon))
    };

    return (
        <div className='mapboxCon'>
            <div className='mapboxCon-container'>
                <div id='mapBox'/>
                <div className='json-content'>
                    <Box
                        sx={{
                            position: 'relative',
                            backgroundColor: 'transparent',
                            padding: '20px',
                            minHeight: '200px',
                        }}>
                        <JsonViewer onGeoJsonUpdate={addLayer} handleCopyToClipboard={handleCopyToClipboard}/>
                    </Box>
                </div>
            </div>
        </div>
    );
}
export default CreateJobMap;
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import {makeStyles} from '@material-ui/core';
import CircularProgressIndicator from '../../components/CustomComponents/CircularProgressIndicator';
import JobDetail from '../../components/JobListTable/JobDetail';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
    card: {
        width: '100%',
        backgroundColor: "#E0E0E0"
        // backgroundColor: 'lightblue',
    },
});

interface JobDetail {
    name: string
    id: string
    status: string
    projectId: string
}


interface JobResponse {
    input_url: string,
    footway_url: string,
    problem_ways_url: string,
    ways_fixed_url: string,
    crossings_fixed_url: string,
    messageType: string
}

 interface Job {
    jobId: string;
    flowId: string;
    currentTask: string;
    flow_description: string;
    request: Object;
    response: Object;
    output_definition: [{ name: string, description: string, type: string,options:[{name:string,value:string,description:string}] }],
    state: string;
}

const API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`

const JobDetails = () => {
    const [jobDetail, setJobDetail] = useState<JobDetail>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const {id} = useParams<{ id?: string }>();
    const itemId = id;
    const navigate = useNavigate();
    const classes = useStyles();
    const [job, setJob] = useState<Job>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(API_BASE_URL + '/job/' + id);
            const data = await response.json();
            setJob(data as Job);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            setError(error + "");
        }
    }

    const navigateToTasks = () => {
        navigate(`/job/${id}/tasks`);
    };
    const pop = () => {
        navigate(-1);
    };


    // if (loading) {
    //     return (<CircularProgressIndicator/>);
    // }

    if (error) {
        return (
            <div>
                <p>Error: {error.toString()}</p>
                <button onClick={pop}>Go Back</button>
            </div>
        );
    }
    return (
        <>
            <div style={{
                'width': '100%',
                'alignContent': 'center',
                'justifyContent': 'center',
                'display': 'block',
                'flexDirection': 'row'
            }}>
                <div>
                 <Typography variant='h6' style={{'paddingTop': '2rem', 'paddingBottom': '2rem'}}>{job?.flow_description}</Typography>
                 </div>

                <JobDetail job={job}/>
            </div>


            <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'justifyContent': 'center',
                'paddingTop': '3rem',
            }}>
                {loading ? <CircularProgressIndicator/> : <Button variant='contained' onClick={() => {
                    setLoading(true);
                    fetchData();
                }}>
                    Refresh Job Status
                </Button>}
            </div>
        </>
    );
};

export default JobDetails;

import React from 'react';
import './ChartStyles.css';
import {BarChartOutlined, StackedLineChart} from '@mui/icons-material';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import PieChartIcon from '@mui/icons-material/PieChart';


interface BarSelectionComponentProps {
    // Add any props you need for the component here
    onChartTypeSelected: (chartType: string | null) => void;
}

const BarSelectionComponent: React.FC<BarSelectionComponentProps> = ({onChartTypeSelected}) => {
    // Add your component logic here
    const [chartType, setChartType] = React.useState<string | null>('bar');

    // Write method to handle the click event of the buttons
    const handleChartSelection = (
        event: React.MouseEvent<HTMLElement>,
        chartType: string | null,
    ) => {
        // console.log("Chart Type Selected --- ", chartType)
        if (chartType !== null) {
            setChartType(chartType.trim());
            onChartTypeSelected(chartType.trim());
        }
    };


    return (
        <div className='chart-buttons'>

            <ToggleButtonGroup size='small' exclusive
                               value={chartType}
                               onChange={handleChartSelection}>
                <ToggleButton value="pie"
                              className={chartType === 'pie' ? 'chart-buttons-selected' : 'chart-buttons-default'}>
                    <PieChartIcon className="chart-buttons-default"/>
                </ToggleButton>
                <ToggleButton value=" bar" defaultChecked={true}
                              className={chartType === 'bar' ? 'chart-buttons-selected' : 'chart-buttons-default'}>
                    <BarChartOutlined className=" chart-buttons-default"/>
                </ToggleButton>
                <ToggleButton value=" line"
                              className={chartType === 'line' ? 'chart-buttons-selected' : 'chart-buttons-default'}>
                    <StackedLineChart className=" chart-buttons-default"/>
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
};

export default BarSelectionComponent;
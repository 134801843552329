import React, {useRef, useState} from 'react';
import Button from "@mui/material/Button";

const {CreateJobMapProps} = require('../../routes/Jobs/CreateNewJob')
const DragDropView = ({onGeoJsonUpdate}) => {
    const [dragging, setDragging] = useState(false);
    const fileInputRef = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const files = [...e.dataTransfer.files];
        // Handle dropped files here, for example, display file names
        files.forEach(file => console.log(file.name));

        setDragging(false);
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const result = event.target.result;
            const geoJSONObj = JSON.parse(result);
            console.log(geoJSONObj); // You can now access the GeoJSON object here
            onGeoJsonUpdate(geoJSONObj);
        };

        reader.readAsText(file);
    };

    return (
        <div
            className={`file-view ${dragging ? 'dragging' : ''}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <input
                type="file"
                accept=".geojson"
                onChange={handleFileInput}
                style={{display: 'none'}}
                ref={fileInputRef}
            />
            <Button fullWidth variant="contained" onClick={() => fileInputRef.current.click()}>Upload file</Button>
        </div>
    );
};

export default DragDropView;

// This class visualizes the data as a pie chart. It is called by the StatsVisualizer based on the data
// passed to it. The data is passed as a prop to the PieChart component which then visualizes the data.


import React, {useEffect, useState} from "react";
import {BarChart} from "@mui/x-charts";
import {makeStyles} from "@material-ui/core";
import {ChartUtils} from "./ChartUtils";


interface BarChartComponentProps {
    chartData: any; // Replace 'any' with the type of your data
}

const useStyles = makeStyles({
    root: {
        backgroundColor: 'black'// Change this to the color you want
    },
});

export const BarChartComponent: React.FC<BarChartComponentProps> = ({chartData}) => {
    const [barChartData, setBarChartData] = useState<any[]>([]);
    const classes = useStyles();

    useEffect(() => {
        if (chartData) {
            if (Array.isArray(chartData)) {
                // Check if the data is an array and contains objects with label and value
                if (chartData.length > 0 && chartData[0].label && chartData[0].value) {
                    setBarChartData(chartData);
                } else {
                    throw new Error("Data does not contain label and value");
                }

            } else if (chartData && typeof chartData === 'object') {
                // Convert the object to an array of objects with label and value
                const convertedData = ChartUtils.convertDataFormat(chartData);
                if (convertedData != null && convertedData.length > 0 && convertedData[0].label && convertedData[0].value) {
                    setBarChartData(convertedData);
                }
            } else {
                throw new Error("Data is compatible with Bar Chart Component");
            }
        }
    }, [chartData]);

    const yValueFormatter = (value: number | null) => {
        if (value != null && typeof value === 'number' && value > 1000) {
            value = Math.round(value) / 1000
            return value + "k";
        } else if (value === null) {
            return "";
        } else if (value != null && typeof value === 'number' && value < 1000) {
            return value.toString();
        } else if (value != null && typeof value === 'string') {
            return value;
        }
        return "";
    };
    const chartSetting = {
        yAxis: [
            {
                dataKey: 'label',
                valueFormatter: (value: string) => yValueFormatter(value as unknown as number),
            },
        ],
        series: [{dataKey: 'value', label: 'Bar Chart Label'}],
        height: 500,
        width: 600,

    };

    return (
        <div className='container-margin'>
            <BarChart
                dataset={barChartData}

                tooltip={{
                    classes: classes,
                }}
                className={'bar-chart'}

                xAxis={[{scaleType: 'band', dataKey: 'label'}]}

                {...chartSetting}
            />
        </div>
    );
}
// import { createTheme } from '@mui/material/styles';
import JobsListTable from '../../components/JobListTable/JobListTable';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useNavigate} from 'react-router-dom';


const Jobs = () => {
    const navigate = useNavigate();
    const navigateToCreateTask = () => {
        navigate(`/createNewJob`);
    };
    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{margin: "10px"}}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Jobs
                </Typography>

            </Box>
            <JobsListTable/>
        </div>
    );
};

export default Jobs;
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}`

export const createNewJob = async ({flow_id, input_geojson}: {
                                       flow_id: string,
                                       input_geojson: Object
                                   }, successCallback: (response: any) => void,
                                   errorCallback: (error: any) => void) => {
    try {
        const newJob = {
            method: 'POST',
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'},
            data: {'flowId': flow_id, 'data': {'input_geojson': input_geojson}},
            url: API_URL + 'flow',
        }
        const response = await axios(newJob);
        console.log(response)
        successCallback(response);
    } catch (error) {
        errorCallback(error);
        console.error('Authentication error:', error);
    }
};
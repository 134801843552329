import Box from '@mui/material/Box';
import CreateJobMap from '../../components/CreateJobMap/CreateJobMap';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {GEOJSON} from '../../utils/data';
import {Divider, Link, ThemeProvider} from '@mui/material';
import {createNewJob} from '../../services/AuthService';
import {AppTheme} from "../../theme/AppTheme";
import CircularProgressIndicator from '../../components/CustomComponents/CircularProgressIndicator';

interface CreateJobMapProps {
}

const CreateNewJob: React.FC<CreateJobMapProps> = () => {
    const navigate = useNavigate();
    const [geoJson, setGeoJson] = useState(GEOJSON);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [response, setResponse] = useState();

    const handleMapDataUpdate = (updatedGeoJson: any) => {
        console.log('Updated geo json:', updatedGeoJson);
        setGeoJson(updatedGeoJson);
    };

    function areAllCoordinatesZero() {
        type CoordArray = (number | string)[];
        const coordinates: CoordArray[] = geoJson === undefined || geoJson.features.length == 0 ? GEOJSON.features[0].geometry.coordinates[0] : geoJson.features[0].geometry.coordinates[0];
        return coordinates.every(coordArray =>
            coordArray.every(element => element === 0)
        );
    }

    const validateForm = (): boolean => {
        const newErrors: { [key: string]: string } = {};

        if (areAllCoordinatesZero()) {
            newErrors.geoJson = 'Job Location is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            console.log(geoJson)
            setLoading(true)
            await createNewJob({flow_id: "24f2bccecff2", "input_geojson": geoJson},
                (data) => {
                    //navigate(-1);
                    console.log(data.data.jobId)
                    setLoading(false)
                    setResponse(data.data.jobId)

                    // alert("Job created successfully. Use this ID to track the status " + data.data.jobId);
                }, (error) => {
                    alert("Error in creating job : " + error)
                    setLoading(false)
                });
        }
    };
    const pop = () => {
        navigate(-1);
    };

    return (
        <div>
            <ThemeProvider theme={AppTheme}>
                <div style={{display: 'flex', flexDirection: 'column'}}>

                    <Typography variant="subtitle1" component="h4" gutterBottom style={{textAlign: 'center'}}>
                        This application identifies and returns proposed fixes to the OpenStreetMaps footways and
                        sidewalks data in a selected region. Read more about the issues it identifies here:
                        {/* <Button color='primary'
                                href="https://medium.com/gaussian-solutions/identifying-and-fixing-untagged-footway-crossings-in-openstreetmap-data-2b9a5e3f1c03"
                                component={Link}>
                            Identifying and fixing Untagged Footway Crossings in OpenStreetMap Data</Button> */}
                        <Typography variant='overline' display='block' gutterBottom color='primary'
                                    href="https://medium.com/gaussian-solutions/identifying-and-fixing-untagged-footway-crossings-in-openstreetmap-data-2b9a5e3f1c03"
                                    component={Link}>
                            Identifying and fixing Untagged Footway Crossings in OpenStreetMap Data</Typography>
                    </Typography>
                    {/* <b> 
                        This application identifies and returns proposed fixes to the OpenStreetMaps footways and
                        sidewalks data in a selected region. Read more about the issues it identifies here:
                        <Button color='primary'
                                href="https://medium.com/gaussian-solutions/identifying-and-fixing-untagged-footway-crossings-in-openstreetmap-data-2b9a5e3f1c03"
                                component={Link}>
                            Identifying and fixing Untagged Footway Crossings in OpenStreetMap Data</Button>
                            </b> */}
                    <Box display="flex"
                         justifyContent="center"
                         alignItems="center"
                         flexDirection="column" // Change flexDirection to 'column'
                         textAlign="center">
                        <Typography variant="h6" component="h1" gutterBottom>
                            Select the region you want analyzed and click
                            <Button onClick={handleSubmit} variant="contained"
                                    sx={{margin: "5px", display: "inline-flex"}}>
                                Analyze
                            </Button>
                            {/* <br/> (or) <DragDropView
                            onGeoJsonUpdate={handleMapDataUpdate}/> */}
                        </Typography>
                    </Box>

                    {/* <Box display="flex" justifyContent="flex-end">
                        {errors.geoJson && <div style={{color: '#c3433c', fontSize: "12px"}}>{errors.geoJson}</div>}
                        <Button onClick={handleSubmit} variant="outlined" sx={{margin: "5px", display: "inline-flex"}}>
                            Analyze
                        </Button>
                    </Box> */}
                    <Divider/>
                    {loading ? <CircularProgressIndicator/> : null}
                    {response ? <Typography variant='body1' component='h1' style={{textAlign: 'center'}}>
                        Job created successfully. Use this {response} to track the status
                        or go to
                        <Button color='primary' href={'/job/' + response} component={Link}>
                            Job
                        </Button>
                    </Typography> : null}
                    <Box display='flex' justifyContent='center'>
                        <div style={{
                            width: '70%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <CreateJobMap onGeoJsonUpdate={handleMapDataUpdate}/>
                        </div>
                    </Box>
                </div>
            </ThemeProvider>
        </div>);
}

export default CreateNewJob;
import React, { useEffect, useRef } from 'react';

import mapboxgl from 'mapbox-gl';
import './ResponseGeoJSONUrlDisplay.scss';
import { Container, Fab } from '@mui/material';
import { Download } from '@mui/icons-material';
import { add } from 'date-fns';

mapboxgl.accessToken = `${process.env.REACT_APP_MAP_KEY}`



interface ResponseGeoJSONUrlDisplayProps {
    url: string;
    name: string;
}

const ResponseGeoJSONUrlDisplay: React.FC<ResponseGeoJSONUrlDisplayProps> = ({ url, name }) => {
    // const map = useRef(null);
    const mapContainer = React.useRef<any>(null);
    const map = React.useRef<mapboxgl.Map | null>(null);
    useEffect(() => {
        
        if (map.current) {
          return; // initialize map only once
        }
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [-118.25448741309731, 34.041946105119706],
            zoom: 10
            });
       map.current.on('load', () => {
        addSource();
       });


    }, [url]);

    const addSource = ()=>{
        map.current!.addSource(name,{
            type: 'geojson',
            data: url
        }); 
        map.current!.addLayer({
            'id': name,
            'type': 'line',
            'source': name,
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': '#888',
                'line-width': 8
            }
        });
    }


    return (
        <Container>
            <p>{name}</p>
            
            <Fab color="secondary" size='small' aria-label="download" onClick={() => {
                console.log('download')
                // redirect to download url
                let ahref= document.createElement('a');
                ahref.href = url;
                ahref.click();

            } }>
                <Download />
            </Fab>
            
            <div ref={mapContainer} className='mapBox'/>
        </Container>
    );
};

export default ResponseGeoJSONUrlDisplay;
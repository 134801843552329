import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {AppTheme} from "../../theme/AppTheme";
import {ThemeProvider, Typography} from "@mui/material";
import ResponseStringDisplay from './ResponseStringDisplay';
import ResponseUrlDisplay from './ResponseUrlDisplay';
import {StatsVisualizer} from '../StatsVisualizations/StatsVisualizer';
import ResponseGeoJSONUrlDisplay from './ResponseGeoJSONUrlDisplay';
// import {Button, Link, ThemeProvider} from "@mui/material";

function createData(name, url, downloadable = true) {
    return {name, url, downloadable};
}

let rows = [];

function createDataForTable(job) {
    rows = [createData("Job ID", job.jobId, false),
        createData("Status", job.state, false),
        createData("Current stage", job.currentTask, false)]
}


const JobDetail = (props) => {
    const {job} = props

    console.log(job)

    if (typeof job !== 'undefined') {
        createDataForTable(job)
    }

    return ((typeof job !== 'undefined') ?
            <div>

                <ThemeProvider theme={AppTheme}>
                    <TableContainer component={Paper} style={{backgroundColor: 'black'}}>
                        <Table sx={{minWidth: 600}} aria-label="simple table" style={{'color': 'black'}}>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.url}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {job.response ?
                        <div>
                            <Typography variant="h6" style={{
                                color: 'white',
                                marginTop: '2rem',
                                marginBottom: '2rem'
                            }}>Response: </Typography>

                            {Object.keys(job.response).map((key) => {
                                const resp_key = key
                                let resp_type = ''
                                // Find the output definition for this key
                                job.output_definition.forEach((output) => {
                                    if (output.name === key) {
                                        resp_type = output
                                    }
                                })

                                switch (resp_type.type) {
                                    case 'string':
                                    case 'enum':
                                        return <ResponseStringDisplay paramDescription={resp_type.description}
                                                                      value={job.response[key]}/>
                                    case 'url':
                                        return <ResponseUrlDisplay name='Download' url={job.response[key]}
                                                                   description={resp_type.description}/>
                                    case 'geojsonurl':
                                        return <ResponseGeoJSONUrlDisplay name={resp_type.description} url={job.response[key]}
                                                                   />
                                    case 'graph':
                                        return <StatsVisualizer data={job.response[key]}/>
                                    default:
                                        return <Typography variant="h6"
                                                           style={{color: 'white'}}>{key}: {resp_type.type}</Typography>
                                }

                                // return
                                // <div key={key}>
                                // {
                                //     switch (resp_type.type) {
                                //         case 'string':
                                //             return <Typography variant="h6" style={{color: 'white'}}>{key}: {job.response[key]}</Typography>

                                // }}
                                // </div>

                                // <div key={key}>
                                //     <Typography variant="h6" style={{color: 'white'}}>{key}: {resp_type.type}</Typography>
                                // </div>
                            })}

                        </div> : null
                    }


                </ThemeProvider>
            </div> : null
    );
}

export default JobDetail;